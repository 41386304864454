var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.impr.ibmStepCd !== "IS00000001",
              expression: "impr.ibmStepCd !== 'IS00000001'",
            },
          ],
          staticClass: "col-12",
        },
        [
          _c("c-step-mobile", {
            attrs: {
              stepperGrpCd: "IBM_STEP_CD",
              currentStepCd: _vm.impr.ibmStepCd,
              disabled: true,
            },
            model: {
              value: _vm.impr.ibmStepCd,
              callback: function ($$v) {
                _vm.$set(_vm.impr, "ibmStepCd", $$v)
              },
              expression: "impr.ibmStepCd",
            },
          }),
        ],
        1
      ),
      _vm.overFlag && _vm.impr.approvalStatusCd !== "ASC9999999"
        ? _c(
            "div",
            { staticClass: "col-12" },
            [
              _c(
                "q-banner",
                {
                  staticClass: "text-white bg-red",
                  attrs: { dense: "", "inline-actions": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "avatar",
                        fn: function () {
                          return [
                            _c("q-icon", {
                              attrs: {
                                name: "report_problem",
                                color: "grey-3",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    407695491
                  ),
                },
                [_c("b", { staticClass: "text-h5" }, [_vm._v("지연")])]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.overFlag,
              expression: "overFlag",
            },
          ],
          staticClass: "col-12",
        },
        [
          _c(
            "q-form",
            { ref: "overForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "지연상세" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            editable: _vm.editable,
                            disabled:
                              _vm.disabled ||
                              _vm.impr.approvalStatusCd === "ASC9999999",
                            required: true,
                            start: _vm.$comm.getToday(),
                            label: "목표일",
                            name: "targetDate",
                          },
                          model: {
                            value: _vm.impr.targetDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.impr, "targetDate", $$v)
                            },
                            expression: "impr.targetDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled:
                              _vm.disabled ||
                              _vm.impr.approvalStatusCd === "ASC9999999",
                            required: true,
                            label: "지연사유",
                            name: "delayReason",
                          },
                          model: {
                            value: _vm.impr.delayReason,
                            callback: function ($$v) {
                              _vm.$set(_vm.impr, "delayReason", $$v)
                            },
                            expression: "impr.delayReason",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
        [
          _c(
            "q-form",
            { ref: "editForm1" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "요청&접수 상세" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [_c("q-btn-group", { attrs: { outline: "" } })],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            required: "",
                            disabled: _vm.isOld || !_vm.taskEditable,
                            editable: _vm.editable,
                            filterItems: _vm.taskFilterItems,
                            codeGroupCd: "IBM_TASK_TYPE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "ibmTaskTypeCd",
                            label: "업무",
                          },
                          on: { datachange: _vm.taskTypeChange },
                          model: {
                            value: _vm.impr.ibmTaskTypeCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.impr, "ibmTaskTypeCd", $$v)
                            },
                            expression: "impr.ibmTaskTypeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            disabled: true,
                            editable: _vm.editable,
                            name: "sopImprovementId",
                            label: "개선번호",
                          },
                          model: {
                            value: _vm.impr.sopImprovementId,
                            callback: function ($$v) {
                              _vm.$set(_vm.impr, "sopImprovementId", $$v)
                            },
                            expression: "impr.sopImprovementId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: true,
                            label: "요청부서/이름/일자",
                            name: "request",
                            value: _vm.request,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.requestAreaEditable,
                            required: true,
                            label: "제목",
                            name: "ibmTitle",
                          },
                          model: {
                            value: _vm.impr.ibmTitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.impr, "ibmTitle", $$v)
                            },
                            expression: "impr.ibmTitle",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.requestAreaEditable,
                            required: true,
                            label: "요청내용",
                            name: "improveRequestContents",
                          },
                          model: {
                            value: _vm.impr.improveRequestContents,
                            callback: function ($$v) {
                              _vm.$set(_vm.impr, "improveRequestContents", $$v)
                            },
                            expression: "impr.improveRequestContents",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-plant", {
                          attrs: {
                            editable: _vm.requestAreaEditable,
                            required: true,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.impr.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.impr, "plantCd", $$v)
                            },
                            expression: "impr.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c(
                          "c-dept",
                          {
                            attrs: {
                              required: _vm.suggestionTaskActionAuth,
                              editable:
                                _vm.requestAreaEditable ||
                                _vm.actionReceiptAreaEditable,
                              searchFlag: _vm.actionReceiptAreaEditable
                                ? false
                                : true,
                              closeFlag: _vm.actionReceiptAreaEditable
                                ? false
                                : true,
                              plantCd: _vm.impr.plantCd,
                              type: "edit",
                              label: "조치부서",
                              name: "actionDeptCd",
                            },
                            model: {
                              value: _vm.impr.actionDeptCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.impr, "actionDeptCd", $$v)
                              },
                              expression: "impr.actionDeptCd",
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "custom-btn-icon" },
                              [
                                _vm.actionReceiptAreaEditable
                                  ? _c(
                                      "q-icon",
                                      {
                                        staticClass: "cursor-pointer",
                                        attrs: { name: "autorenew" },
                                        on: { click: _vm.changeActionDeptImpr },
                                      },
                                      [
                                        _c(
                                          "q-tooltip",
                                          {
                                            attrs: {
                                              anchor: "top middle",
                                              self: "bottom middle",
                                              offset: [10, 10],
                                            },
                                          },
                                          [_c("strong", [_vm._v("부서변경")])]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-multi-field", {
                          attrs: {
                            editable: _vm.actionReceiptAreaEditable,
                            required: true,
                            userInfo: _vm.userInfo,
                            isArray: "",
                            label: "조치자",
                            name: "actionUserIds",
                          },
                          on: {
                            "update:userInfo": function ($event) {
                              _vm.userInfo = $event
                            },
                            "update:user-info": function ($event) {
                              _vm.userInfo = $event
                            },
                          },
                          model: {
                            value: _vm.impr.actionUserIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.impr, "actionUserIds", $$v)
                            },
                            expression: "impr.actionUserIds",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            editable: _vm.requestAreaEditable,
                            required: true,
                            label: "조치완료 요청일",
                            name: "actionCompleteRequestDate",
                          },
                          model: {
                            value: _vm.impr.actionCompleteRequestDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.impr,
                                "actionCompleteRequestDate",
                                $$v
                              )
                            },
                            expression: "impr.actionCompleteRequestDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            required: _vm.suggestionTaskActionAuth,
                            editable:
                              _vm.requestAreaEditable ||
                              _vm.actionReceiptAreaEditable,
                            data: _vm.impr,
                            deptValue: "actionCompleteCheckDeptCd",
                            label: "조치부서 담당자",
                            name: "actionCompleteCheckUserId",
                          },
                          model: {
                            value: _vm.impr.actionCompleteCheckUserId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.impr,
                                "actionCompleteCheckUserId",
                                $$v
                              )
                            },
                            expression: "impr.actionCompleteCheckUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.actionReceiptAreaEditable,
                            label: "요청취소 사유",
                            name: "requestRejectReason",
                          },
                          model: {
                            value: _vm.impr.requestRejectReason,
                            callback: function ($$v) {
                              _vm.$set(_vm.impr, "requestRejectReason", $$v)
                            },
                            expression: "impr.requestRejectReason",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-upload", {
                          attrs: {
                            attachInfo: _vm.attachBeforeInfo,
                            editable:
                              _vm.requestAreaEditable ||
                              _vm.actionReceiptAreaEditable ||
                              _vm.actionAreaEditable,
                            label: "개선 전 사진",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "q-form",
                  { ref: "editForm2" },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm",
                        attrs: { title: "조치상세" },
                      },
                      [
                        _c(
                          "template",
                          { slot: "card-button" },
                          [_c("q-btn-group", { attrs: { outline: "" } })],
                          1
                        ),
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-datepicker", {
                                attrs: {
                                  editable: _vm.actionAreaEditable,
                                  required: true,
                                  label: "조치완료일",
                                  name: "actionCompleteDate",
                                },
                                model: {
                                  value: _vm.impr.actionCompleteDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.impr,
                                      "actionCompleteDate",
                                      $$v
                                    )
                                  },
                                  expression: "impr.actionCompleteDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                            },
                            [
                              _c("c-datepicker", {
                                attrs: {
                                  editable: _vm.actionAreaEditable,
                                  required: true,
                                  label: "검토완료 요청일",
                                  name: "reviewCompleteRequestDate",
                                },
                                model: {
                                  value: _vm.impr.reviewCompleteRequestDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.impr,
                                      "reviewCompleteRequestDate",
                                      $$v
                                    )
                                  },
                                  expression: "impr.reviewCompleteRequestDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-textarea", {
                                attrs: {
                                  editable: _vm.actionAreaEditable,
                                  required: true,
                                  label: "조치내용",
                                  name: "actionContents",
                                },
                                model: {
                                  value: _vm.impr.actionContents,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.impr, "actionContents", $$v)
                                  },
                                  expression: "impr.actionContents",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.isWriteCost
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-12 col-sm-6 col-md-6 col-lg-6",
                                },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      editable: _vm.actionAreaEditable,
                                      required: true,
                                      type: "number",
                                      label: "개선 시 발생비용",
                                      name: "imprCost",
                                    },
                                    model: {
                                      value: _vm.impr.imprCost,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.impr, "imprCost", $$v)
                                      },
                                      expression: "impr.imprCost",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("c-upload", {
                                attrs: {
                                  attachInfo: _vm.attachAfterInfo,
                                  editable: _vm.actionAreaEditable,
                                  label: "개선 후 사진",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "q-form",
                  { ref: "editForm3" },
                  [
                    _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm",
                        attrs: { title: "검토상세" },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-textarea", {
                                attrs: {
                                  editable: _vm.requestCheckAreaEditable,
                                  required: true,
                                  label: "검토내용",
                                  name: "reviewContents",
                                },
                                model: {
                                  value: _vm.impr.reviewContents,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.impr, "reviewContents", $$v)
                                  },
                                  expression: "impr.reviewContents",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "q-dialog",
        {
          ref: "deptDialog",
          attrs: { position: "bottom", persistent: "" },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "q-card",
            { staticClass: "dept-card" },
            [
              _c(
                "q-card-section",
                {
                  staticClass:
                    "row items-center q-pb-none px-2 py-1 bg-orange-7 text-white",
                },
                [
                  _c("div", { staticClass: "text-h6" }, [
                    _vm._v("조치부서 변경"),
                  ]),
                  _c("q-space"),
                  _c("q-btn", {
                    attrs: { icon: "done", flat: "", round: "", dense: "" },
                    on: { click: _vm.selectDept },
                  }),
                  _c("q-btn", {
                    directives: [
                      { name: "close-popup", rawName: "v-close-popup" },
                    ],
                    attrs: { icon: "close", flat: "", round: "", dense: "" },
                  }),
                ],
                1
              ),
              _c(
                "q-item",
                [
                  _c(
                    "q-item-section",
                    [
                      _c(
                        "q-item-label",
                        { attrs: { caption: "" } },
                        [
                          _c(
                            "q-chip",
                            {
                              attrs: {
                                outline: "",
                                square: "",
                                color: "primary",
                                "text-color": "white",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.deptName) + " ")]
                          ),
                          _c("q-input", {
                            ref: "filter",
                            attrs: { dense: "", label: "Filter" },
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [
                                    _vm.filter !== ""
                                      ? _c("q-icon", {
                                          staticClass: "cursor-pointer",
                                          attrs: { name: "clear" },
                                          on: { click: _vm.resetFilter },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.filter,
                              callback: function ($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("q-separator"),
              _c(
                "q-card-section",
                { staticClass: "dept-content p-2" },
                [
                  _c("q-tree", {
                    ref: "imprActionDept",
                    attrs: {
                      nodes: _vm.deptTree,
                      "node-key": "deptCd",
                      "label-key": "deptName",
                      "children-key": "children",
                      "no-nodes-label": "부서 정보가 없습니다.",
                      "no-results-label": "필터링 된 데이터가 없습니다.",
                      filter: _vm.filter,
                      "default-expand-all": true,
                      "selected-color": "primary",
                      selected: _vm.selected,
                    },
                    on: {
                      "update:selected": function ($event) {
                        _vm.selected = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _c(_vm.relationTableInfo.component, {
                  key: _vm.relationTableInfo.key,
                  tag: "component",
                  attrs: {
                    taskParam: _vm.relationTableInfo.taskParam,
                    disabled: !Boolean(_vm.popupParam.sopImprovementId),
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.rejectBtnEditable,
                      expression: "rejectBtnEditable",
                    },
                  ],
                  attrs: {
                    flat: true,
                    isSubmit: _vm.isReturn,
                    url: _vm.saveUrl,
                    param: _vm.impr,
                    mappingType: "PUT",
                    label: "반려",
                    icon: "front_hand",
                  },
                  on: {
                    beforeAction: _vm.returnImpr,
                    btnCallback: _vm.returnCallback,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.completeBtnEditable,
                      expression: "completeBtnEditable",
                    },
                  ],
                  attrs: {
                    flat: true,
                    isSubmit: _vm.isComplete,
                    url: _vm.saveUrl,
                    param: _vm.impr,
                    mappingType: "PUT",
                    label: "개선완료",
                    icon: "check_circle_outline",
                  },
                  on: {
                    beforeAction: _vm.completeImpr,
                    btnCallback: _vm.completeCallback,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.saveBtnEditable3,
                      expression: "saveBtnEditable3",
                    },
                  ],
                  attrs: {
                    flat: true,
                    isSubmit: _vm.isSaveCheck,
                    url: _vm.saveUrl,
                    param: _vm.impr,
                    mappingType: "PUT",
                    label: "저장",
                    icon: "save",
                  },
                  on: {
                    beforeAction: _vm.saveCheckImpr,
                    btnCallback: _vm.saveCheckCallback,
                  },
                }),
                _c("c-appr-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.overFlag,
                      expression: "overFlag",
                    },
                  ],
                  ref: "appr-btn",
                  attrs: {
                    flat: true,
                    name: "work-permit-appr-btn",
                    editable:
                      _vm.editable &&
                      _vm.impr.approvalStatusCd !== "ASC9999999",
                    approvalInfo: _vm.approvalInfo,
                    historyBtnLabel: "지연 결재이력",
                    reApprBtnLabel: "지연 재상신",
                    apprBtnLabel: "지연 결재요청",
                  },
                  on: {
                    beforeApprAction: _vm.approvalOverImpr,
                    callbackApprAction: _vm.approvalOverImrpCallback,
                    requestAfterAction: _vm.getDetail,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.editable &&
                        !_vm.disabled &&
                        _vm.impr.approvalStatusCd !== "ASC9999999" &&
                        _vm.overFlag,
                      expression:
                        "editable&&!disabled&&impr.approvalStatusCd !== 'ASC9999999'&&overFlag",
                    },
                  ],
                  attrs: {
                    flat: true,
                    isSubmit: _vm.isOver,
                    url: _vm.saveUrl,
                    param: _vm.impr,
                    mappingType: "PUT",
                    label: "저장",
                    icon: "save",
                  },
                  on: {
                    beforeAction: _vm.saveOverImpr,
                    btnCallback: _vm.saveOverCallback,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.requestAreaEditable &&
                        _vm.popupParam.sopImprovementId,
                      expression:
                        "requestAreaEditable&&popupParam.sopImprovementId",
                    },
                  ],
                  attrs: {
                    flat: true,
                    isSubmit: _vm.isDelete,
                    url: _vm.deleteUrl,
                    param: _vm.impr,
                    mappingType: "DELETE",
                    label: "삭제",
                    icon: "remove",
                  },
                  on: {
                    beforeAction: _vm.removeImpr,
                    btnCallback: _vm.removeCallback,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.requestBtnEditable,
                      expression: "requestBtnEditable",
                    },
                  ],
                  attrs: {
                    flat: true,
                    isSubmit: _vm.isRequest,
                    url: _vm.saveUrl,
                    param: _vm.impr,
                    mappingType: "PUT",
                    label: "조치부서에 요청",
                    icon: "send",
                  },
                  on: {
                    beforeAction: _vm.requestImpr,
                    btnCallback: _vm.requestCallback,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.requestCancelBtnEditable,
                      expression: "requestCancelBtnEditable",
                    },
                  ],
                  attrs: {
                    flat: true,
                    isSubmit: _vm.isReject,
                    url: _vm.saveUrl,
                    param: _vm.impr,
                    mappingType: "PUT",
                    label: "요청취소",
                    icon: "delete_forever",
                  },
                  on: {
                    beforeAction: _vm.rejectImpr,
                    btnCallback: _vm.rejectCallback,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.receiptBtnEditable,
                      expression: "receiptBtnEditable",
                    },
                  ],
                  attrs: {
                    flat: true,
                    isSubmit: _vm.isReceipt,
                    url: _vm.saveUrl,
                    param: _vm.impr,
                    mappingType: "PUT",
                    label: "접수",
                    icon: "directions_run",
                  },
                  on: {
                    beforeAction: _vm.receiptImpr,
                    btnCallback: _vm.receiptCallback,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.saveBtnEditable1,
                      expression: "saveBtnEditable1",
                    },
                  ],
                  attrs: {
                    flat: true,
                    isSubmit: _vm.isSaveRequestReceipt,
                    url: _vm.saveUrl,
                    param: _vm.impr,
                    mappingType: _vm.mappingType,
                    label: "저장",
                    icon: "save",
                  },
                  on: {
                    beforeAction: _vm.saveRequestReceiptImpr,
                    btnCallback: _vm.saveRequestReceiptCallback,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.actionCompleteBtnEditable,
                      expression: "actionCompleteBtnEditable",
                    },
                  ],
                  attrs: {
                    flat: true,
                    isSubmit: _vm.isActionComplete,
                    url: _vm.saveUrl,
                    param: _vm.impr,
                    mappingType: "PUT",
                    label: "조치완료",
                    icon: "directions_run",
                  },
                  on: {
                    beforeAction: _vm.actionCompleteImpr,
                    btnCallback: _vm.actionCompleteCallback,
                  },
                }),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.saveBtnEditable2,
                      expression: "saveBtnEditable2",
                    },
                  ],
                  attrs: {
                    flat: true,
                    isSubmit: _vm.isSaveAction,
                    url: _vm.saveUrl,
                    param: _vm.impr,
                    mappingType: "PUT",
                    label: "저장",
                    icon: "save",
                  },
                  on: {
                    beforeAction: _vm.saveActionImpr,
                    btnCallback: _vm.saveActionCallback,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }